import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActionConfig, RequestAction } from '../../interfaces/action.interface';
import { HttpResponse } from '../../interfaces/http.inteface';
import { Subject, take } from 'rxjs';
import { Action } from '../../constants/action.const';
import {
    ApprovalConfirmationComponent
} from '../../../shared/components/approval/approval-confirmation/approval-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import {
    ConfirmationDialogComponent
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import {
    ConfirmationDecisionComponent
} from '../../../shared/components/confirmation-decision/confirmation-decision.component';

@Injectable({
    providedIn: 'root'
})
export class ActionService {k

    constructor(
        private http: HttpClient,
        private matDialog: MatDialog) {
    }

    triggerAction(config: ActionConfig) {
        const subject: Subject<any> = new Subject();
        const headers = new HttpHeaders({
            disabledToast: config.disableToast ? config.disableToast + '' : ''
        });
        if (!config?.disableToast) {
            headers.delete('disabledToast');
        }

        switch (config.actionCode) {
            case Action.SEND:
            case Action.BINDING:
            case Action.SEND_FOR_APPROVAL:
            case Action.FINALIZE:
            case Action.PLACEMENT:
            case Action.ACTIVATED:
            case Action.TERMINATED:
            case Action.REACTIVATE:
            case Action.RESET_PASS:
            case Action.LOCK:
            case Action.UNLOCK:
            case Action.DELETE:
            case Action.CANCEL:
            case Action.POSTING:
            case Action.ISSUE:
            case Action.UNPOSTING:
            case Action.REVERSE:
                const cancelDialogRef = this.matDialog.open(ConfirmationDialogComponent, {
                    autoFocus: false,
                    data: config,
                    width: '500px'
                });

                cancelDialogRef.afterClosed().subscribe((resp) => {
                    if (resp) {
                        const body: Partial<RequestAction> = {
                            action: config.actionCode,
                            id1: config?.id1 ? config?.id1 : config.id,
                            id2: config?.id2,
                            id3: config?.id3,
                        };
                        this.http.post<HttpResponse<string>>(config.url, body, { headers })
                            .pipe(take(1))
                            .subscribe((resp) => {
                                subject.next(resp);
                            });
                    }
                });
                break;
            case Action.APPROVAL:
                const dialogRef = this.matDialog.open(ApprovalConfirmationComponent, {
                    autoFocus: false,
                    data: {
                        actionType: 'ADD',
                        title: config.section
                    },
                });

                dialogRef.afterClosed().subscribe((resp) => {
                    if (resp) {
                        const body: Partial<RequestAction> = {
                            action: resp.action,
                            id1: config.id,
                            notes: resp.notes
                        };
                        this.http.post<HttpResponse<string>>(config.url, body, { headers })
                            .pipe(take(1))
                            .subscribe((resp) => {
                                subject.next(resp);
                            });
                    }
                });
                break;
            case Action.CONFIRM:
                const confirmationDialogRef = this.matDialog.open(ConfirmationDecisionComponent, {
                    autoFocus: false,
                    data: {
                        actionType: 'ADD',
                        title: config.section,
                        lov: config.lov.confirmationList,
                    },
                });

                confirmationDialogRef.afterClosed().subscribe((resp) => {
                    if (resp) {
                        const body: Partial<RequestAction> = {
                            action: resp.action,
                            id1: config.id,
                            notes: resp.notes
                        };
                        this.http.post<HttpResponse<string>>(config.url, body, { headers })
                            .pipe(take(1))
                            .subscribe((resp) => {
                                subject.next(resp);
                            });
                    }
                });
                break;
            default:
                const body: Partial<RequestAction> = {
                    action: config.actionCode,
                    id1: config.id,
                };
                this.http.post<HttpResponse<string>>(config.url, body, { headers })
                    .pipe(take(1))
                    .subscribe((resp) => {
                        subject.next(resp);
                    });
                break;
        }

        return subject;
    }

}
