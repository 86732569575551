<div class="nxe-modal">
    <div class="nxe-modal-header">
        <mat-icon
            svgIcon="mat_outline:warning">
        </mat-icon>
        <div>
            <p class="subheader"> {{ actionConfig?.section }} </p>
        </div>
    </div>
    <div class="nxe-modal-content">
        <div [innerHTML]="actionConfig?.text"></div>
    </div>
    <div class="nxe-modal-footer">
        <dx-button
            buttonLoading
            (onClick)="onContinue()"
            class="nxe-btn-primary"
            height="35"
            width="100"
            type="default">
                <mat-icon style="margin-left: -8px;" [ngStyle]="{'color':'white'}">check_circle</mat-icon>
                <span>{{actionConfig.btnYesText}}</span>
        </dx-button>
        <dx-button 
            (onClick)="onCancel()"
            height="35"
            width="100">
                <mat-icon style="margin-left: -8px;">cancel</mat-icon>
                <span>{{actionConfig.btnNoText}}</span>
        </dx-button>
    </div>
</div>
