import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { MatIconModule } from '@angular/material/icon';
import { DxListModule } from 'devextreme-angular/ui/list';
import { MatMenuModule } from '@angular/material/menu';
import {
    DxDateBoxModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTemplateModule,
    DxValidationGroupModule,
    DxValidatorModule
} from 'devextreme-angular';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { LovService } from '../../../../core/services/core/lov.service';
import { LovEntity } from '../../../../core/interfaces/lov.interface';
import { DEFAULT_LOV_ENTITY } from '../../../../core/constants/lov.const';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonLoadingDirective } from '../../../directives/button-loading.directive';

@Component({
    selector: 'app-approval-confirmation',
    standalone: true,
    templateUrl: './approval-confirmation.component.html',
    styleUrls: [ './approval-confirmation.component.scss' ],
    imports: [
        CommonModule,
        DxDataGridModule,
        DxTabPanelModule,
        DxTextBoxModule,
        DxButtonModule,
        MatIconModule,
        DxListModule,
        MatMenuModule,
        DxPopupModule,
        DxValidationGroupModule,
        DxSelectBoxModule,
        DxTextAreaModule,
        DxTemplateModule,
        DxScrollViewModule,
        ReactiveFormsModule,
        DxValidatorModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        ButtonLoadingDirective,
    ],
})
export class ApprovalConfirmationComponent {
    destroyRef = inject(DestroyRef);
    validationGroup = 'APPROVAL_CONFIRMATION';

    title = '';
    formGroup: FormGroup = new FormGroup({
        action: new FormControl(),
        notes: new FormControl(),
    });

    lov: LovEntity = DEFAULT_LOV_ENTITY;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public matDialogRef: MatDialogRef<ApprovalConfirmationComponent>,
                private lovService: LovService,) {
        this.title = data.title;
        this.fetchLov();
    }

    fetchLov() {
        this.lovService.getLovEntity()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.lov = resp.data;
            })
    }

    onContinue(event: any): void {
        let result = event.validationGroup.validate();
        if (result.isValid) {
            const body = this.formGroup.value;
            this.matDialogRef.close(body);
        }
    }

    close(): void {
        this.matDialogRef.close();
    }

}
