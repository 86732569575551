import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DxButtonModule,
    DxNumberBoxModule,
    DxSelectBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxValidatorModule
} from 'devextreme-angular';
import { DxiValidationRuleModule } from 'devextreme-angular/ui/nested';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ActionConfig } from '../../../core/interfaces/action.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action } from '../../../core/constants/action.const';
import { ButtonLoadingDirective } from 'app/shared/directives/button-loading.directive';

@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [CommonModule, DxButtonModule, DxNumberBoxModule, DxSelectBoxModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule, DxiValidationRuleModule, FormsModule, MatIconModule, ReactiveFormsModule, ButtonLoadingDirective],
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

    actionConfig: Partial<ActionConfig> = {
        btnYesText: 'Ok',
        btnNoText: 'Cancel'
    }
    constructor(private matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private config: ActionConfig) {
        this.populateConfig();
    }

    populateConfig() {
        this.actionConfig = {
            ...this.actionConfig,
            ...this.config,
        }

        if (!this.config?.subsection) {
            switch (this.actionConfig.actionCode) {
                case Action.POSTING: 
                    this.actionConfig.subsection = 'post';
                    break;
                case Action.UNPOSTING: 
                    this.actionConfig.subsection = 'unpost';
                    break;
                case Action.CANCEL:
                    this.actionConfig.subsection = 'Cancel';
                    break;
                case Action.REVERSE:
                    this.actionConfig.subsection = 'Reverse';
                    break;
                case Action.DELETE:
                    this.actionConfig.subsection = 'Remove';
                    break;
                case Action.ACTIVATED:
                    this.actionConfig.subsection = 'Activated';
                    break;
                case Action.TERMINATED:
                    this.actionConfig.subsection = 'Terminated';
                    break;
                case Action.REACTIVATE:
                    this.actionConfig.subsection = 'Reactivate';
                    break;
                case Action.RESET_PASS:
                    this.actionConfig.subsection = 'Reset Password';
                    break;
                case Action.LOCK:
                    this.actionConfig.subsection = 'Lock';
                    break;
                case Action.UNLOCK:
                    this.actionConfig.subsection = 'UnLock';
                    break;
                case Action.SEND_FOR_APPROVAL:
                    this.actionConfig.subsection = 'Request for Approval';
                    break;
                case Action.FINALIZE:
                    this.actionConfig.subsection = 'Finalize';
                    break;
                case Action.PLACEMENT:
                    this.actionConfig.subsection = 'Placement';
                    break;
                case Action.SEND:
                    this.actionConfig.subsection = 'Send to Ceding';
                    break;
                case Action.BINDING:
                    this.actionConfig.subsection = 'Binding';
                    break;
                case Action.ISSUE:
                    this.actionConfig.subsection = 'Issue';
                    break;
            }
        }

        if (!this.config?.text) {
            this.actionConfig.text =
                `You are about to <strong>${this.actionConfig.subsection.toLowerCase()}</strong> this data. <br>
                Click Ok to continue`;
        }
    }

    onCancel() {
        this.matDialogRef.close();
    }

    onContinue() {
        this.matDialogRef.close(true);
    }

}
