import { Component, DestroyRef, inject, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { MatIconModule } from '@angular/material/icon';
import { DxListModule } from 'devextreme-angular/ui/list';
import { MatMenuModule } from '@angular/material/menu';
import {
    DxNumberBoxModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTemplateModule,
    DxValidationGroupModule,
    DxValidatorModule
} from 'devextreme-angular';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { LovService } from '../../../core/services/core/lov.service';
import { Lov } from '../../../core/interfaces/lov.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-confirmation-decision',
    standalone: true,
    templateUrl: './confirmation-decision.component.html',
    styleUrls: [ './confirmation-decision.component.scss' ],
    imports: [
        CommonModule,
        DxDataGridModule,
        DxTabPanelModule,
        DxTextBoxModule,
        DxButtonModule,
        MatIconModule,
        DxListModule,
        MatMenuModule,
        DxPopupModule,
        DxValidationGroupModule,
        DxSelectBoxModule,
        DxTextAreaModule,
        DxTemplateModule,
        DxScrollViewModule,
        ReactiveFormsModule,
        DxValidatorModule,
        DxNumberBoxModule,
    ],
})
export class ConfirmationDecisionComponent {
    destroyRef = inject(DestroyRef);
    validationGroup = 'CONFIRMATION_DECISION';

    title = '';
    formGroup: FormGroup = new FormGroup({
        action: new FormControl(),
        notes: new FormControl(),
    });

    lov: Lov[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public matDialogRef: MatDialogRef<ConfirmationDecisionComponent>,
                private lovService: LovService,) {
        this.title = data.title;
        if (data.lov) {
            this.lov = data.lov;
        } else {
            this.fetchLov();
        }
    }

    fetchLov() {
        this.lovService.getLovEntity()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((resp) => {
                this.lov = resp.data.approvalDecisionList;
            })
    }

    onContinue(event: any): void {
        let result = event.validationGroup.validate();
        if (result.isValid) {
            const body = this.formGroup.value;
            this.matDialogRef.close(body);
        }
    }

    close(): void {
        this.matDialogRef.close();
    }

}
