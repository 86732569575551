<div class="nxe-modal">
    <div class="nxe-modal-header">
        <mat-icon svgIcon="heroicons_outline:shopping-cart">
        </mat-icon>
        <div>
            <p>{{ title }}</p>
            <p class="subheader">Confirmation</p>
        </div>
    </div>
    <div class="nxe-modal-content">
        <dx-scroll-view #confirmScrollView [scrollByContent]="true" [scrollByThumb]="true" [showScrollbar]="'onScroll'"
            id="confirmScrollview">
            <form [formGroup]="formGroup">
                <div class="grid grid-cols-8 gap-2 mb-1 mt-4">
                    <div class="col-start-1 col-span-8">
                        <div class="dx-field flex flex-row w-full">
                            <div class="dx-field-label font-medium pt-2 w-2/5">
                                Decision
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-select-box [dataSource]="lov.approvalDecisionList" [minSearchLength]="0"
                                    [searchEnabled]="true" [searchExpr]="'lovName'" [searchMode]="'contains'"
                                    [searchTimeout]="200" [showClearButton]="true" [showDataBeforeSearch]="false"
                                    displayExpr="lovName" formControlName="action" valueExpr="lovCode">
                                    <dx-validator [validationGroup]="validationGroup">
                                        <dxi-validation-rule message="Decision is required" type="required">
                                        </dxi-validation-rule>
                                    </dx-validator>
                                </dx-select-box>
                            </div>
                        </div>
                    </div>


                    <div class="col-start-1 col-span-8">
                        <div class="dx-field flex flex-row w-full">
                            <div class="dx-field-label font-medium pt-2 w-2/5">
                                Notes
                            </div>
                            <div class="dx-field-value w-full">
                                <dx-text-area [height]="80" formControlName="notes" placeholder="Notes...">
                                </dx-text-area>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </dx-scroll-view>
    </div>
    <div class="nxe-modal-footer">
        <dx-button buttonLoading height="35" width="100" (onClick)="onContinue($event)"
            [validationGroup]="validationGroup" class="nxe-btn-primary" type="default">
            <mat-icon style="margin-left: -8px;" [ngStyle]="{'color':'white'}">play_arrow</mat-icon>
            <span>Continue</span>
        </dx-button>
        <dx-button (click)="close()" height="35" width="100">
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </dx-button>
    </div>
</div>